import React from 'react';
import { useSpring, animated } from 'react-spring';
import CardPiece from './CardPiece';

function CardProductInfo(props) {

    const style = useSpring({ opacity: 1, from: {opacity: 0}});

    return (
        <animated.div className="g-card-info" style={style}>
            <p className="g-card-title">{props.category}</p>

        <CardPiece />

            {/* <div>{props.pieces[0].imgSrc}</div> */}
            <a href={props.link} target="_blank" rel="noopener noreferrer">View</a>
        </animated.div>

    
    );
}

export default CardProductInfo;


{/* <animated.div className="g-card-info" style={style}>
            <p className="g-card-title">{props.category}</p>
            <div>{props.pieces[0].imgSrc}</div>
            <a href={props.link} target="_blank" rel="noopener noreferrer">View</a>
</animated.div> */}