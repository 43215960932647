import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// electronics images
import p134 from '../assets/images/resizedimages/gallery069.jpg';


function Electronics(props) {
    const electronics = [
        {id: 129, title: 'Electronics', price: 20.00, imgSrc: p134, selected: false}

    ];
    
       

    return (

        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>ELECTRONICS</h3>

                {electronics.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts'/>
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
      
           
    );

    }

export default Electronics;