import React from 'react';
import Hero from '../components/Hero';
import CategoryProducts from '../components/CategoryProducts';
import Products from '../components/Products';
import ProductsPieces from '../components/ProductsPieces';
import Homedecor from '../components/Homedecor';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import forth from '../assets/images/otherphotos/newwhitebackgroundphotos/rrr/gallery14_clipped_rev_1.png';
import fivth from '../assets/images/otherphotos/newwhitebackgroundphotos/rrr/gallery22_clipped_rev_1.png';
import sixth from '../assets/images/otherphotos/newwhitebackgroundphotos/rrr/gallery18_clipped_rev_2.png';
import seventh from '../assets/images/otherphotos/newwhitebackgroundphotos/rrr/gallery15_clipped_rev_1.png';


function ProductPage(props) {
    return (
        <div className="product-page">

            <Hero title={props.title} 
                subTitle={props.subTitle}
                text={props.text}
            />
            <CategoryProducts/>
            {/* <Products /> */}
      {/* <ProductsPieces /> */}


            <Container fluid>
            <Row className="d-flex productsImage row justify-content-center mt-5">
                <Col>
                    <img src={forth} className="w-100 thumbnail" style={{'width':'150px'}} />
                    <p className="textprice">ceramic plate<br></br> 80.00 NIS</p>
                </Col>
                <Col>
                    <img src={fivth} className="w-100 thumbnail" style={{'width':'150px'}} />
                    <p className="textprice">enamel sugarvase<br></br> 65.00 NIS</p>
                </Col>
                <Col>
                    <img src={sixth} className="w-100 thumbnail" style={{'width':'150px'}} />
                    <p className="textprice">porcelain teapot<br></br> 100.00 NIS</p>
                </Col>
                <Col>
                    <img src={seventh} className="w-100 thumbnail" style={{'width':'150px'}} />
                    <p className="textprice">wooden doll<br></br> 120.00 NIS</p>
                </Col>
            </Row>
            </Container>
        </div>

    );
}

export default ProductPage; 