import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// art images
import p75 from '../assets/images/resizedimages/gallery127.jpg';
import p76 from '../assets/images/resizedimages/gallery112.jpg';
import p77 from '../assets/images/resizedimages/gallery116.jpg';
import p78 from '../assets/images/resizedimages/gallery118.jpg';
import p79 from '../assets/images/resizedimages/gallery119.jpg';
import p80 from '../assets/images/resizedimages/gallery128.jpg';
import p81 from '../assets/images/resizedimages/gallery117.jpg';
import p82 from '../assets/images/resizedimages/gallery130.jpg';
import p83 from '../assets/images/resizedimages/gallery124.jpg';
import p84 from '../assets/images/resizedimages/gallery126.jpg';
import p85 from '../assets/images/resizedimages/gallery125.jpg';
import p86 from '../assets/images/resizedimages/gallery123.jpg';
import p87 from '../assets/images/resizedimages/gallery129.jpg';
import p88 from '../assets/images/resizedimages/gallery113.jpg';
import p89 from '../assets/images/resizedimages/gallery115.jpg';
import p90 from '../assets/images/resizedimages/gallery114.jpg';
import p91 from '../assets/images/resizedimages/gallery122.jpg';
import p92 from '../assets/images/resizedimages/gallery131.jpg';

function Art(props) {
    const art = [
       // art array
       {id: 73, title: 'Art', price: 120.00, imgSrc: p75, selected: false},
       {id: 74, title: 'Art', price: 80.00, imgSrc: p76, selected: false},
       {id: 75, title: 'Art', price: 45.00, imgSrc: p77, selected: false},
       {id: 76, title: 'Art', price: 300.00, imgSrc: p78, selected: false},
       {id: 77, title: 'Art', price: 300.00, imgSrc: p79, selected: false},
       {id: 78, title: 'Art', price: 500.00, imgSrc: p80, selected: false},
       {id: 79, title: 'Art', price: 500.00, imgSrc: p81, selected: false},
       {id: 80, title: 'Art', price: 1200.00, imgSrc: p82, selected: false},
       {id: 81, title: 'Art', price: 600.00, imgSrc: p83, selected: false},
       {id: 82, title: 'Art', price: 200.00, imgSrc: p84, selected: false},
       {id: 83, title: 'Art', price: 200.00, imgSrc: p85, selected: false},
       {id: 84, title: 'Art', price: 70.00, imgSrc: p86, selected: false},
       {id: 85, title: 'Art', price: 800.00, imgSrc: p87, selected: false},
       {id: 86, title: 'Art', price: 300.00, imgSrc: p88, selected: false},
       {id: 87, title: 'Art', price: 500.00, imgSrc: p89, selected: false},
       {id: 88, title: 'Art', price: 500.00, imgSrc: p90, selected: false},
       {id: 89, title: 'Art', price: 500.00, imgSrc: p91, selected: false},
       {id: 90, title: 'Art', price: 1000.00, imgSrc: p92, selected: false}
    ];
    
       

    return (
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>ART</h3>
                {art.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts' />
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
    )
            
    }

export default Art;