import React from 'react';
import Hero from '../components/Hero';
import RealC from '../components/RealC';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import first from '../assets/images/resizedimages/gallery017.jpg';
import second from '../assets/images/resizedimages/gallery090.jpg';
import third from '../assets/images/resizedimages/gallery131.jpg';
import forth from '../assets/images/resizedimages/gallery080.jpg';

import fivth from '../assets/images/whitebackground/IMG_6966 (1)_clipped_rev_1.png';



function HomePage(props) {
    return (
        <div className="home-page">
        <Hero title={props.title}
              subTitle={props.subTitle}
              text={props.text}
        />
        <RealC />
           
            <Container fluid className="enjoy-section p-5">
                <Row>
                    <Col>
                        <h4 className="text-home p-1">Enjoy stylish collections according to various topics, like historical epoches, imaginative literature, 
                        style directions, cinemas, etc.</h4>
                        <img src={first} className='w-100 homeImage thumbnail' style={{maxWidth: "600px", height: "auto"}} />
                        <img src={second} className='w-100 homeImage thumbnail' style={{maxWidth: "600px", height: "auto"}} />
                    </Col>
                    <Col>
                        <img src={third} className='w-100 homeImage thumbnail' style={{maxWidth: "600px", height: "auto"}} />
                        <img src={forth} className='w-100 homeImage thumbnail' style={{maxWidth: "600px", height: "auto"}} />
                        <h4 className="text-home pt-2">Be the one who posseses unique and vintage things and lifestyle.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                        <Row className="shelf d-flex justify-content-center">
                            <img src={fivth} className='w-100' style={{maxWidth: "1000px", height: "auto", transform: "scaleX(-1)"}} />
                        </Row>
            </Container>
       </div> 

    );
}

export default HomePage; 