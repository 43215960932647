import React from 'react';
import { useState } from 'react';

//import Modal from 'react-bootstrap/Modal';
//import ModalHeader from 'react-bootstrap/ModalHeader';
//import ModalTitle from 'react-bootstrap/ModalTitle';
//import ModalBody from 'react-bootstrap/ModalBody'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// toys images
import p114 from '../assets/images/resizedimages/gallery170.jpg';
import p115 from '../assets/images/resizedimages/gallery2.jpg';
import p116 from '../assets/images/resizedimages/gallery44.jpg';
import p117 from '../assets/images/resizedimages/gallery43.jpg';
import p118 from '../assets/images/resizedimages/gallery135.jpg';
import p119 from '../assets/images/resizedimages/gallery132.jpg';
import p120 from '../assets/images/resizedimages/gallery15.jpg';

function Toys(props) {
    const toys = [
        {id: 111, title: 'Toys', price: 120.00, imgSrc: p114, selected: false},
        {id: 112, title: 'Toys', price: 50.00, imgSrc: p115, selected: false},
        {id: 113, title: 'Toys', price: 150.00, imgSrc: p116, selected: false},
        {id: 114, title: 'Toys', price: 40.00, imgSrc: p117, selected: false},
        {id: 115, title: 'Toys', price: 80.00, imgSrc: p118, selected: false},
        {id: 116, title: 'Toys', price: 140.00, imgSrc: p119, selected: false},
        {id: 117, title: 'Toys', price: 35.00, imgSrc: p120, selected: false}
    ];
    
   // const [lgShow, setLgShow] = useState(false);


   /* return (
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
                {toys.map(el => 
                        <div className="p-2 m-2">
                            <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)} aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">{el.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts' onClick={() => setLgShow(true)} />
                            <p>{el.price} {'NIS'}</p>
                            </Modal.Body>
                            </Modal>
                        </div>)}
        </Row>
        </Container>

    );*/

    return (
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>TOYS</h3>

                {toys.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts' />
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
            </Row>
        </Container>
    );


    }

export default Toys;