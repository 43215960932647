import React from 'react';
import Card from '../components/Card';


import first from '../assets/images/resizedimages/gallery10.jpg';
import second from '../assets/images/resizedimages/gallery005.jpg';
import third from '../assets/images/resizedimages/gallery119.jpg';
import forth from '../assets/images/resizedimages/gallery022.jpg';
import fivth from '../assets/images/resizedimages/gallery002.jpg';
import sixth from '../assets/images/otherphotos/finalpics/productspics/gallery02.jpg';
import seventh from '../assets/images/resizedimages/gallery37.jpg';
import eighth from '../assets/images/resizedimages/gallery069.jpg';




import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class CategoryProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 0,
                    title: 'Homedecor',
                    imgSrc: first,
                    selected: false
                },
                {
                    id: 1,
                    title: 'Service dishes',
                    imgSrc: second,
                    selected: false
                },
                {
                    id: 2,
                    title: 'Art',
                    imgSrc: third,
                    selected: false
                },
                {
                    id: 3,
                    title: 'Jewerly',
                    imgSrc: forth,
                    selected: false
                },
                {
                    id: 4,
                    title: 'Toys',
                    imgSrc: fivth,
                    selected: false
                },
                {
                    id: 5,
                    title: 'Books',
                    imgSrc: sixth,
                    selected: false
                },
                {
                    id: 6,
                    title: 'Clothing and accessorize',
                    imgSrc: seventh,
                    selected: false
                },
                {
                    id: 7,
                    title: 'Electronics',
                    imgSrc: eighth,
                    selected: false
                }

            ]
        }
    }
    
    handleCardClick = (id, card) => {
        let items = [...this.state.items];
        items[id].selected = items[id].selected ? false : true; 
        items.forEach(item => {
            if (item.id !== id) {
                item.selected = false;
            }
        });

        this.setState({
            items
        });
    }

    makeItems = (items) => {
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id} />
        })
    }


    
    render() {
        return (
            <Container fluid={true}>

            <Row className="cardholder catproducts d-flex justify-content-center">
                    {this.makeItems(this.state.items)}

            </Row>
            </Container>
        );
    }
}



export default CategoryProducts; 