import React from 'react';
import CardProductInfo from '../components/CardProductInfo';

function CardProduct(props) {
    return (
        <div className="d-inline-block g-card" onClick={(e) => props.click(props.piece)}>
        <div>{props.product.category}</div>
            {/* <img className="g-card-image" sr={props.product.pieces.imgSrc} alt={props.product.pieces.imgSrc} /> */}
            {<img className="g-card-image" src={props.product.pieces[0].imgSrc} alt={props.product.pieces.imgSrc} />}
            {props.product.selected && <CardProductInfo category={props.product.category}  />}
        </div>
    );
}





export default CardProduct;

