import React from 'react';
import CardPiece from './CardPiece';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


// homedecor images
import p1 from '../assets/images/resizedimages/gallery01.jpg';
import p2 from '../assets/images/resizedimages/gallery107.jpg';
import p3 from '../assets/images/resizedimages/gallery17.jpg';
import p4 from '../assets/images/resizedimages/gallery09.jpg';
import p5 from '../assets/images/resizedimages/gallery050.jpg';
import p6 from '../assets/images/resizedimages/gallery04.jpg';
import p7 from '../assets/images/resizedimages/gallery174.jpg';
import p8 from '../assets/images/resizedimages/gallery176.jpg';
import p9 from '../assets/images/resizedimages/gallery173.jpg';
import p10 from '../assets/images/resizedimages/gallery06.jpg';
import p11 from '../assets/images/resizedimages/gallery011.jpg';
import p12 from '../assets/images/resizedimages/gallery20.jpg';
import p13 from '../assets/images/resizedimages/gallery45.jpg';
import p14 from '../assets/images/resizedimages/gallery171.jpg';
import p15 from '../assets/images/resizedimages/gallery047.jpg';
import p16 from '../assets/images/resizedimages/gallery08.jpg';
import p17 from '../assets/images/resizedimages/gallery004.jpg';
import p18 from '../assets/images/resizedimages/gallery084.jpg';
import p19 from '../assets/images/resizedimages/gallery46.jpg';
import p20 from '../assets/images/resizedimages/gallery35.jpg';
import p21 from '../assets/images/resizedimages/gallery104.jpg';
import p22 from '../assets/images/resizedimages/gallery084.jpg';
import p23 from '../assets/images/resizedimages/gallery11.jpg';
import p24 from '../assets/images/resizedimages/gallery01.jpg';
import p25 from '../assets/images/resizedimages/gallery34.jpg';
import p26 from '../assets/images/resizedimages/gallery07.jpg';
import p27 from '../assets/images/resizedimages/gallery046.jpg';
import p28 from '../assets/images/resizedimages/gallery001.jpg';
import p29 from '../assets/images/resizedimages/gallery049.jpg';
import p30 from '../assets/images/resizedimages/gallery28.jpg';
import p31 from '../assets/images/resizedimages/gallery22.jpg';
import p32 from '../assets/images/resizedimages/gallery003.jpg';
import p33 from '../assets/images/resizedimages/gallery045.jpg';
import p34 from '../assets/images/resizedimages/gallery087.jpg';
import p35 from '../assets/images/resizedimages/gallery016.jpg';
import p36 from '../assets/images/resizedimages/gallery32.jpg';
import p37 from '../assets/images/resizedimages/gallery101.jpg';
import p38 from '../assets/images/resizedimages/gallery088.jpg';
import p39 from '../assets/images/resizedimages/gallery101.jpg';
import p40 from '../assets/images/resizedimages/gallery111.jpg';
import p41 from '../assets/images/resizedimages/gallery090.jpg';
import p42 from '../assets/images/resizedimages/gallery101.jpg';
import p43 from '../assets/images/resizedimages/gallery02.jpg';

// service dishes images
import p44 from '../assets/images/resizedimages/gallery09.jpg';
import p45 from '../assets/images/resizedimages/gallery153.jpg';
import p46 from '../assets/images/resizedimages/gallery147.jpg';
import p47 from '../assets/images/resizedimages/gallery152.jpg';
import p48 from '../assets/images/resizedimages/gallery145.jpg';
import p49 from '../assets/images/resizedimages/gallery148.jpg';
import p50 from '../assets/images/resizedimages/gallery144.jpg';
import p51 from '../assets/images/resizedimages/gallery138.jpg';
import p52 from '../assets/images/resizedimages/gallery079.jpg';
import p53 from '../assets/images/resizedimages/gallery146.jpg';
import p54 from '../assets/images/resizedimages/gallery150.jpg';
import p55 from '../assets/images/resizedimages/gallery156.jpg';
import p56 from '../assets/images/resizedimages/gallery146.jpg';
import p57 from '../assets/images/resizedimages/gallery149.jpg';
import p58 from '../assets/images/resizedimages/gallery063.jpg';
import p59 from '../assets/images/resizedimages/gallery052.jpg';
import p60 from '../assets/images/resizedimages/gallery164.jpg';
import p61 from '../assets/images/resizedimages/gallery083.jpg';
import p62 from '../assets/images/resizedimages/gallery082.jpg';
import p63 from '../assets/images/resizedimages/gallery081.jpg';
import p64 from '../assets/images/resizedimages/gallery062.jpg';
import p65 from '../assets/images/resizedimages/gallery137.jpg';
import p66 from '../assets/images/resizedimages/gallery059.jpg';
import p67 from '../assets/images/resizedimages/gallery26.jpg';
import p68 from '../assets/images/resizedimages/gallery060.jpg';
import p69 from '../assets/images/resizedimages/gallery054.jpg';
import p70 from '../assets/images/resizedimages/gallery13.jpg';
import p71 from '../assets/images/resizedimages/gallery24.jpg';
import p72 from '../assets/images/resizedimages/gallery057.jpg';
import p73 from '../assets/images/resizedimages/gallery058.jpg';
import p74 from '../assets/images/resizedimages/gallery050.jpg';

// art images
import p75 from '../assets/images/resizedimages/gallery127.jpg';
import p76 from '../assets/images/resizedimages/gallery112.jpg';
import p77 from '../assets/images/resizedimages/gallery116.jpg';
import p78 from '../assets/images/resizedimages/gallery118.jpg';
import p79 from '../assets/images/resizedimages/gallery119.jpg';
import p80 from '../assets/images/resizedimages/gallery128.jpg';
import p81 from '../assets/images/resizedimages/gallery117.jpg';
import p82 from '../assets/images/resizedimages/gallery130.jpg';
import p83 from '../assets/images/resizedimages/gallery124.jpg';
import p84 from '../assets/images/resizedimages/gallery126.jpg';
import p85 from '../assets/images/resizedimages/gallery125.jpg';
import p86 from '../assets/images/resizedimages/gallery123.jpg';
import p87 from '../assets/images/resizedimages/gallery129.jpg';
import p88 from '../assets/images/resizedimages/gallery113.jpg';
import p89 from '../assets/images/resizedimages/gallery115.jpg';
import p90 from '../assets/images/resizedimages/gallery114.jpg';
import p91 from '../assets/images/resizedimages/gallery122.jpg';
import p92 from '../assets/images/resizedimages/gallery131.jpg';

// jewerly images
import p93 from '../assets/images/resizedimages/gallery020.jpg';
import p94 from '../assets/images/resizedimages/gallery067.jpg';
import p95 from '../assets/images/resizedimages/gallery161.jpg';
import p96 from '../assets/images/resizedimages/gallery167.jpg';
import p97 from '../assets/images/resizedimages/gallery066.jpg';
import p98 from '../assets/images/resizedimages/gallery166.jpg';
import p99 from '../assets/images/resizedimages/gallery163.jpg';
import p100 from '../assets/images/resizedimages/gallery162.jpg';
import p101 from '../assets/images/resizedimages/gallery164.jpg';
import p102 from '../assets/images/resizedimages/gallery022.jpg';
import p103 from '../assets/images/resizedimages/gallery030.jpg';
import p104 from '../assets/images/resizedimages/gallery037.jpg';
import p105 from '../assets/images/resizedimages/gallery025.jpg';
import p106 from '../assets/images/resizedimages/gallery027.jpg';
import p107 from '../assets/images/resizedimages/gallery032.jpg';
import p108 from '../assets/images/resizedimages/gallery023.jpg';
import p109 from '../assets/images/resizedimages/gallery044.jpg';
import p110 from '../assets/images/resizedimages/gallery039.jpg';
import p111 from '../assets/images/resizedimages/gallery033.jpg';
import p112 from '../assets/images/resizedimages/gallery035.jpg';
import p113 from '../assets/images/resizedimages/gallery028.jpg';

// toys images
import p114 from '../assets/images/resizedimages/gallery170.jpg';
import p115 from '../assets/images/resizedimages/gallery2.jpg';
import p116 from '../assets/images/resizedimages/gallery44.jpg';
import p117 from '../assets/images/resizedimages/gallery43.jpg';
import p118 from '../assets/images/resizedimages/gallery135.jpg';
import p119 from '../assets/images/resizedimages/gallery132.jpg';
import p120 from '../assets/images/resizedimages/gallery15.jpg';

// books images
import p121 from '../assets/images/resizedimages/gallery133.jpg';

// clothing and accessorize images
import p122 from '../assets/images/resizedimages/gallery078.jpg';
import p123 from '../assets/images/resizedimages/gallery068.jpg';
import p124 from '../assets/images/resizedimages/gallery075.jpg';
import p125 from '../assets/images/resizedimages/gallery074.jpg';
import p126 from '../assets/images/resizedimages/gallery077.jpg';
import p127 from '../assets/images/resizedimages/gallery085.jpg';
import p128 from '../assets/images/resizedimages/gallery071.jpg';
import p129 from '../assets/images/resizedimages/gallery51.jpg';
import p130 from '../assets/images/resizedimages/gallery05.jpg';
import p131 from '../assets/images/resizedimages/gallery14.jpg';

// electronics images
import p134 from '../assets/images/resizedimages/gallery069.jpg';




class ProductsPieces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pieces:
            [
            // homedecor array
                {   
                    id: 0, 
                    title: 'Homedecor',
                    price: 2.00, 
                    imgSrc: p1, 
                    selected: false
                },
                {
                    id: 1,
                    title: 'Homedecor',
                    price: 2.00, 
                    imgSrc: p2, 
                    selected: false
                },
                {
                    id: 2, 
                    title: 'Homedecor',
                    price: 2.00, 
                    imgSrc: p3, 
                    selected: false
                },
                    {id: 3, title: 'Homedecor', price: 2.00, imgSrc: p4, selected: false},
                    {id: 4, title: 'Homedecor', price: 2.00, imgSrc: p5, selected: false},
                    {id: 5, title: 'Homedecor', price: 2.00, imgSrc: p6, selected: false},
                    {id: 6, title: 'Homedecor', price: 2.00, imgSrc: p7, selected: false},
                    {id: 7, title: 'Homedecor', price: 2.00, imgSrc: p8, selected: false},
                    {id: 8, title: 'Homedecor', price: 2.00, imgSrc: p9, selected: false},
                    {id: 9, title: 'Homedecor', price: 2.00, imgSrc: p10, selected: false},
                    {id: 10, title: 'Homedecor', price: 2.00, imgSrc: p11, selected: false},
                    {id: 11, title: 'Homedecor',price: 2.00, imgSrc: p12, selected: false},
                    {id: 12, title: 'Homedecor', price: 2.00, imgSrc: p13, selected: false},
                    {id: 13, title: 'Homedecor', price: 2.00, imgSrc: p14, selected: false},
                    {id: 14, title: 'Homedecor', price: 2.00, imgSrc: p15, selected: false},
                    {id: 15, title: 'Homedecor', price: 2.00, imgSrc: p16, selected: false},
                    {id: 16, title: 'Homedecor', price: 2.00, imgSrc: p17, selected: false},
                    {id: 17, title: 'Homedecor', price: 2.00, imgSrc: p18, selected: false},
                    {id: 18, title: 'Homedecor', price: 2.00, imgSrc: p19, selected: false},
                    {id: 19, title: 'Homedecor', price: 2.00, imgSrc: p20, selected: false},
                    {id: 20, title: 'Homedecor', price: 2.00, imgSrc: p21, selected: false},
                    {id: 21, title: 'Homedecor', price: 2.00, imgSrc: p22, selected: false},
                    {id: 22, title: 'Homedecor', price: 2.00, imgSrc: p23, selected: false},
                    {id: 23, title: 'Homedecor', price: 2.00, imgSrc: p24, selected: false},
                    {id: 24, title: 'Homedecor', price: 2.00, imgSrc: p25, selected: false},
                    {id: 25, title: 'Homedecor', price: 2.00, imgSrc: p26, selected: false},
                    {id: 26, title: 'Homedecor', price: 2.00, imgSrc: p27, selected: false},
                    {id: 27, title: 'Homedecor', price: 2.00, imgSrc: p28, selected: false},
                    {id: 28, title: 'Homedecor', price: 2.00, imgSrc: p29, selected: false},
                    {id: 29, title: 'Homedecor', price: 2.00, imgSrc: p30, selected: false},
                    {id: 30, title: 'Homedecor', price: 2.00, imgSrc: p31, selected: false},
                    {id: 31, title: 'Homedecor', price: 2.00, imgSrc: p32, selected: false},
                    {id: 32, title: 'Homedecor', price: 2.00, imgSrc: p33, selected: false},
                    {id: 33, title: 'Homedecor', price: 2.00, imgSrc: p34, selected: false},
                    {id: 34, title: 'Homedecor', price: 2.00, imgSrc: p35, selected: false},
                    {id: 35, title: 'Homedecor', price: 2.00, imgSrc: p36, selected: false},
                    {id: 36, title: 'Homedecor', price: 2.00, imgSrc: p37, selected: false},
                    {id: 37, title: 'Homedecor', price: 2.00, imgSrc: p38, selected: false},
                    {id: 38, title: 'Homedecor', price: 2.00, imgSrc: p39, selected: false},
                    {id: 39, title: 'Homedecor', price: 2.00, imgSrc: p40, selected: false},
                    {id: 40, title: 'Homedecor', price: 2.00, imgSrc: p41, selected: false},
                    {id: 41, title: 'Homedecor', price: 2.00, imgSrc: p42, selected: false},
                    {id: 42, title: 'Homedecor', price: 2.00, imgSrc: p43, selected: false},
            // service dishes array
                        {id: 43, title: 'Service dishes', price: 2.00, imgSrc: p44, selected: false},
                        {id: 44, title: 'Service dishes', price: 2.00, imgSrc: p45, selected: false},
                        {id: 45, title: 'Service dishes', price: 2.00, imgSrc: p46, selected: false},
                        {id: 46, title: 'Service dishes', price: 2.00, imgSrc: p47, selected: false},
                        {id: 47, title: 'Service dishes', price: 2.00, imgSrc: p48, selected: false},
                        {id: 48, title: 'Service dishes', price: 2.00, imgSrc: p49, selected: false},
                        {id: 49, title: 'Service dishes', price: 2.00, imgSrc: p50, selected: false},
                        {id: 50, title: 'Service dishes', price: 2.00, imgSrc: p51, selected: false},
                        {id: 51, title: 'Service dishes', price: 2.00, imgSrc: p52, selected: false},
                        {id: 52, title: 'Service dishes', price: 2.00, imgSrc: p53, selected: false},
                        {id: 53, title: 'Service dishes', price: 2.00, imgSrc: p54, selected: false},
                        {id: 54, title: 'Service dishes', price: 2.00, imgSrc: p55, selected: false},
                        {id: 55, title: 'Service dishes', price: 2.00, imgSrc: p56, selected: false},
                        {id: 56, title: 'Service dishes', price: 2.00, imgSrc: p57, selected: false},
                        {id: 57, title: 'Service dishes', price: 2.00, imgSrc: p58, selected: false},
                        {id: 58, title: 'Service dishes', price: 2.00, imgSrc: p59, selected: false},
                        {id: 59, title: 'Service dishes', price: 2.00, imgSrc: p60, selected: false},
                        {id: 60, title: 'Service dishes', price: 2.00, imgSrc: p61, selected: false},
                        {id: 61, title: 'Service dishes', price: 2.00, imgSrc: p62, selected: false},
                        {id: 62, title: 'Service dishes', price: 2.00, imgSrc: p63, selected: false},
                        {id: 63, title: 'Service dishes', price: 2.00, imgSrc: p64, selected: false},
                        {id: 64, title: 'Service dishes', price: 2.00, imgSrc: p65, selected: false},
                        {id: 65, title: 'Service dishes', price: 2.00, imgSrc: p66, selected: false},
                        {id: 130, title: 'Service dishes', price: 2.00, imgSrc: p67, selected: false},
                        {id: 66, title: 'Service dishes', price: 2.00, imgSrc: p68, selected: false},
                        {id: 67, title: 'Service dishes', rice: 2.00, imgSrc: p69, selected: false},
                        {id: 68, title: 'Service dishes', price: 2.00, imgSrc: p70, selected: false},
                        {id: 69, title: 'Service dishes', price: 2.00, imgSrc: p71, selected: false},
                        {id: 70, title: 'Service dishes', price: 2.00, imgSrc: p72, selected: false},
                        {id: 71, title: 'Service dishes', price: 2.00, imgSrc: p73, selected: false},
                        {id: 72, title: 'Service dishes', price: 2.00, imgSrc: p74, selected: false},
            // art array
                        {id: 73, title: 'Art', price: 2.00, imgSrc: p75, selected: false},
                        {id: 74, title: 'Art', price: 2.00, imgSrc: p76, selected: false},
                        {id: 75, title: 'Art', price: 2.00, imgSrc: p77, selected: false},
                        {id: 76, title: 'Art', price: 2.00, imgSrc: p78, selected: false},
                        {id: 77, title: 'Art', price: 2.00, imgSrc: p79, selected: false},
                        {id: 78, title: 'Art', price: 2.00, imgSrc: p80, selected: false},
                        {id: 79, title: 'Art', price: 2.00, imgSrc: p81, selected: false},
                        {id: 80, title: 'Art', price: 2.00, imgSrc: p82, selected: false},
                        {id: 81, title: 'Art', price: 2.00, imgSrc: p83, selected: false},
                        {id: 82, title: 'Art', price: 2.00, imgSrc: p84, selected: false},
                        {id: 83, title: 'Art', price: 2.00, imgSrc: p85, selected: false},
                        {id: 84, title: 'Art', price: 2.00, imgSrc: p86, selected: false},
                        {id: 85, title: 'Art', price: 2.00, imgSrc: p87, selected: false},
                        {id: 86, title: 'Art', price: 2.00, imgSrc: p88, selected: false},
                        {id: 87, title: 'Art', price: 2.00, imgSrc: p89, selected: false},
                        {id: 88, title: 'Art', price: 2.00, imgSrc: p90, selected: false},
                        {id: 89, title: 'Art', price: 2.00, imgSrc: p91, selected: false},
                        {id: 90, title: 'Art', price: 2.00, imgSrc: p92, selected: false},
                // jewerly array
                        {id: 91, title: 'Jewerly', price: 2.00, imgSrc: p93, selected: false},
                        {id: 92, title: 'Jewerly', price: 2.00, imgSrc: p94, selected: false},
                        {id: 93, title: 'Jewerly', price: 2.00, imgSrc: p95, selected: false},
                        {id: 94, title: 'Jewerly', price: 2.00, imgSrc: p96, selected: false},
                        {id: 95, title: 'Jewerly', price: 2.00, imgSrc: p97, selected: false},
                        {id: 96, title: 'Jewerly', price: 2.00, imgSrc: p98, selected: false},
                        {id: 97, title: 'Jewerly', price: 2.00, imgSrc: p99, selected: false},
                        {id: 98, title: 'Jewerly', price: 2.00, imgSrc: p100, selected: false},
                        {id: 99, title: 'Jewerly', price: 2.00, imgSrc: p101, selected: false},
                        {id: 100, title: 'Jewerly', price: 2.00, imgSrc: p102, selected: false},
                        {id: 101, title: 'Jewerly', price: 2.00, imgSrc: p103, selected: false},
                        {id: 102, title: 'Jewerly', price: 2.00, imgSrc: p104, selected: false},
                        {id: 103, title: 'Jewerly', price: 2.00, imgSrc: p105, selected: false},
                        {id: 104, title: 'Jewerly', price: 2.00, imgSrc: p106, selected: false},
                        {id: 105, title: 'Jewerly', price: 2.00, imgSrc: p107, selected: false},
                        {id: 106, title: 'Jewerly', price: 2.00, imgSrc: p108, selected: false},
                        {id: 107, title: 'Jewerly', price: 2.00, imgSrc: p109, selected: false},
                        {id: 129, title: 'Jewerly', price: 2.00, imgSrc: p134, selected: false},
                        {id: 108, title: 'Jewerly', price: 2.00, imgSrc: p111, selected: false},
                        {id: 109, title: 'Jewerly', price: 2.00, imgSrc: p112, selected: false},
                        {id: 110, title: 'Jewerly', price: 2.00, imgSrc: p113, selected: false},
                        {id: 131, title: 'Jewerly', price: 2.00, imgSrc: p110, selected: false},
                // toys array
                        {id: 111, title: 'Toys', price: 2.00, imgSrc: p114, selected: false},
                        {id: 112, title: 'Toys', price: 2.00, imgSrc: p115, selected: false},
                        {id: 113, title: 'Toys', price: 2.00, imgSrc: p116, selected: false},
                        {id: 114, title: 'Toys', price: 2.00, imgSrc: p117, selected: false},
                        {id: 115, title: 'Toys', price: 2.00, imgSrc: p118, selected: false},
                        {id: 116, title: 'Toys', price: 2.00, imgSrc: p119, selected: false},
                        {id: 117, title: 'Toys', price: 2.00, imgSrc: p120, selected: false},
                // books array
                        {id: 118, title: 'Books',price: 2.00, imgSrc: p121, selected: false},
                // clothing and accessorizes
                        {id: 119, title: 'Clothing and accessorize', price: 2.00, imgSrc: p122, selected: false},
                        {id: 120, title: 'Clothing and accessorize', price: 2.00, imgSrc: p123, selected: false},
                        {id: 121, title: 'Clothing and accessorize', price: 2.00, imgSrc: p124, selected: false},
                        {id: 122, title: 'Clothing and accessorize', price: 2.00, imgSrc: p125, selected: false},
                        {id: 123, title: 'Clothing and accessorize', price: 2.00, imgSrc: p126, selected: false},
                        {id: 124, title: 'Clothing and accessorize', price: 2.00, imgSrc: p127, selected: false},
                        {id: 125, title: 'Clothing and accessorize', price: 2.00, imgSrc: p128, selected: false},
                        {id: 126, title: 'Clothing and accessorize', price: 2.00, imgSrc: p129, selected: false},
                        {id: 127, title: 'Clothing and accessorize', price: 2.00, imgSrc: p130, selected: false},
                        {id: 128, title: 'Clothing and accessorize', price: 2.00, imgSrc: p131, selected: false},
                // electronics
                        {id: 129, title: 'Electronics', price: 2.00, imgSrc: p134, selected: false}
            ] 
        }
    }

    handleCardClick = (id, card) => {
        let pieces = [...this.state.pieces];
        pieces[id].selected = pieces[id].selected ? false : true; 
        pieces.forEach(piece => {
            if (piece.id !== id) {
                piece.selected = false;
            }
        });

        this.setState({
            pieces
        });
    }

    makeItems = (pieces) => {
        return pieces.map(piece => {
            return <CardPiece piece={piece} click={(e => this.handleCardClick(piece.id, e))} key={pieces.id} />
        })
    }

    render() {
        return (
            <Container fluid={true}>
                <Row className="cardholder justify-content-center">
               {this.makeItems(this.state.pieces)}
                </Row>
            </Container>
        );
    }
}


export default ProductsPieces; 