import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Cardinfo from '../components/Cardinfo';
import Homedecor from '../components/Homedecor';
import Servicedishes from '../components/Servicedishes';
import Art from '../components/Art';
import Books from '../components/Books';
import Electronics from '../components/Electronics';
import Toys from '../components/Toys';
import Clothing from '../components/Clothing';
import Jewerly from '../components/Jewerly';
import ProductPage from '../pages/ProductPage';



function Card(props) {

    let history = useHistory();
    const redirect = () => {
        history.push('/view')
    }
   
    /*return (
        <div className="card-image-b">
            <div className="d-inline-block g-card" onClick={(e) => props.click(props.item)}> 

            <div>{props.item.title}</div>
                <div><img className="g-card-image" src={props.item.imgSrc} alt={props.item.imgSrc} /></div>
            </div> 
          {props.item.selected && <Cardinfo title={props.item.title} link={props.item.link} />} 
        </div>
    ); */ 

    return (
        <div className="card-image-b">

        <div className="d-inline-block g-card" onClick={(e) => props.click(props.item)}>
        <div>{props.item.title}</div>
            <img className="g-card-image" src={props.item.imgSrc} alt={props.item.imgSrc} />
            {/*<img className="g-card-image" src={props.item.imgSrc} alt={props.item.imgSrc} onClick={redirect}/> */}
            {/*{props.item.selected && <Cardinfo title={props.item.title} />} */}
          {props.item.selected && <Cardinfo title={props.item.title} />}


        
            {/*{props.item.selected && <View title={props.item.title} src={props.item.imgSrc} alt={props.item.imgSrc} />} */}
        </div>


        </div> 
    );

    }

export default Card;