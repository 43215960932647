import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../assets/images/VH2.jpg';


function Footer() {
    return (
        <footer className="footer">
            <Container fluid={true}>
                <Row className="justify-content-between p-3">
                    <Col className="brand justify-content-start p-0" md={3} sm={12}>
                        <img 
                            src={logo}
                            width="20"
                            height="20"
                            className="d-inline-block logo align-top"
                        />
                            Vintage Hub TLV
                    </Col>
                  
                {/* <Row className="addresscolor d-flex column justify-content-between">
                    <Col><p>Vintage Hub TLV</p></Col>
                    <Col><p>Hakishon 79</p></Col>
                    <Col><p>Tel Aviv-Yafo, Israel</p></Col>
                    <Col><p>054-5274597</p></Col>
                </Row> */}
         
                    <Row className="justify-content-end">
                    <Col><a href="https://www.facebook.com/vintagehubtlv"  style={{'color': '#FF3EA5FF', 'text-decoration': 'none'}}>Facebook</a></Col>
                    <Col><a href="https://www.instagram.com/vintage_hub_tlv/" style={{'color': '#FF3EA5FF', 'text-decoration': 'none'}}>Instagram</a></Col>
                    </Row>    
                </Row>
            </Container>
            <Container fluid>
                <Row className="addresscolor d-flex column justify-content-center">
                    <Col className="d-flex justify-content-center"><p>Vintage Hub TLV</p></Col>
                    <Col><p>Hakishon 79</p></Col>
                    <Col><p>Tel Aviv-Yafo, Israel</p></Col>
                    <Col><p>054-5274597</p></Col>
                    <Col><p className="copyrightowner">&#169; created by GalKhafiz</p></Col>
                </Row>
            </Container>
            
           
           
           
        </footer>
    );
}

export default Footer; 

