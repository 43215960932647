import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// jewerly images
import p93 from '../assets/images/resizedimages/gallery020.jpg';
import p94 from '../assets/images/resizedimages/gallery067.jpg';
import p95 from '../assets/images/resizedimages/gallery161.jpg';
import p96 from '../assets/images/resizedimages/gallery167.jpg';
import p97 from '../assets/images/resizedimages/gallery066.jpg';
import p98 from '../assets/images/resizedimages/gallery166.jpg';
import p99 from '../assets/images/resizedimages/gallery163.jpg';
import p100 from '../assets/images/resizedimages/gallery162.jpg';
import p101 from '../assets/images/resizedimages/gallery164.jpg';
import p102 from '../assets/images/resizedimages/gallery022.jpg';
import p103 from '../assets/images/resizedimages/gallery030.jpg';
import p104 from '../assets/images/resizedimages/gallery037.jpg';
import p105 from '../assets/images/resizedimages/gallery025.jpg';
import p106 from '../assets/images/resizedimages/gallery027.jpg';
import p107 from '../assets/images/resizedimages/gallery032.jpg';
import p108 from '../assets/images/resizedimages/gallery023.jpg';
import p109 from '../assets/images/resizedimages/gallery044.jpg';
import p110 from '../assets/images/resizedimages/gallery039.jpg';
import p111 from '../assets/images/resizedimages/gallery033.jpg';
import p112 from '../assets/images/resizedimages/gallery035.jpg';
import p113 from '../assets/images/resizedimages/gallery028.jpg';


function Jewerly(props) {
    const jewerly = [
        {id: 91, title: 'Jewerly', price: 150.00, imgSrc: p93, selected: false},
        {id: 92, title: 'Jewerly', price: 25.00, imgSrc: p94, selected: false},
        {id: 93, title: 'Jewerly', price: 45.00, imgSrc: p95, selected: false},
        {id: 94, title: 'Jewerly', price: 40.00, imgSrc: p96, selected: false},
        {id: 95, title: 'Jewerly', price: 25.00, imgSrc: p97, selected: false},
        {id: 96, title: 'Jewerly', price: 20.00, imgSrc: p98, selected: false},
        {id: 97, title: 'Jewerly', price: 35.00, imgSrc: p99, selected: false},
        {id: 98, title: 'Jewerly', price: 80.00, imgSrc: p100, selected: false},
        {id: 99, title: 'Jewerly', price: 45.00, imgSrc: p101, selected: false},
        {id: 100, title: 'Jewerly', price: 200.00, imgSrc: p102, selected: false},
        {id: 101, title: 'Jewerly', price: 50.00, imgSrc: p103, selected: false},
        {id: 102, title: 'Jewerly', price: 50.00, imgSrc: p104, selected: false},
        {id: 103, title: 'Jewerly', price: 50.00, imgSrc: p105, selected: false},
        {id: 104, title: 'Jewerly', price: 50.00, imgSrc: p106, selected: false},
        {id: 105, title: 'Jewerly', price: 50.00, imgSrc: p107, selected: false},
        {id: 106, title: 'Jewerly', price: 50.00, imgSrc: p108, selected: false},
        {id: 107, title: 'Jewerly', price: 50.00, imgSrc: p109, selected: false},
        {id: 108, title: 'Jewerly', price: 50.00, imgSrc: p111, selected: false},
        {id: 109, title: 'Jewerly', price: 50.00, imgSrc: p112, selected: false},
        {id: 110, title: 'Jewerly', price: 50.00, imgSrc: p113, selected: false},
        {id: 131, title: 'Jewerly', price: 50.00, imgSrc: p110, selected: false}
    ];
    
       

    return (
      
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>JEWERLY</h3>

                {jewerly.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts'/>
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
            
    );

    }

export default Jewerly;