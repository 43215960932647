import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// service dishes images
import p44 from '../assets/images/resizedimages/gallery09.jpg';
import p45 from '../assets/images/resizedimages/gallery153.jpg';
import p46 from '../assets/images/resizedimages/gallery147.jpg';
import p47 from '../assets/images/resizedimages/gallery152.jpg';
import p48 from '../assets/images/resizedimages/gallery145.jpg';
import p49 from '../assets/images/resizedimages/gallery148.jpg';
import p50 from '../assets/images/resizedimages/gallery144.jpg';
import p51 from '../assets/images/resizedimages/gallery138.jpg';
import p52 from '../assets/images/resizedimages/gallery079.jpg';
import p53 from '../assets/images/resizedimages/gallery146.jpg';
import p54 from '../assets/images/resizedimages/gallery150.jpg';
import p55 from '../assets/images/resizedimages/gallery156.jpg';
import p56 from '../assets/images/resizedimages/gallery18.jpg';
import p57 from '../assets/images/resizedimages/gallery143.jpg';
import p58 from '../assets/images/resizedimages/gallery063.jpg';
import p59 from '../assets/images/resizedimages/gallery052.jpg';
import p60 from '../assets/images/resizedimages/gallery141.jpg';
import p61 from '../assets/images/resizedimages/gallery083.jpg';
import p62 from '../assets/images/resizedimages/gallery082.jpg';
import p63 from '../assets/images/resizedimages/gallery081.jpg';
import p64 from '../assets/images/resizedimages/gallery062.jpg';
import p65 from '../assets/images/resizedimages/gallery137.jpg';
import p66 from '../assets/images/resizedimages/gallery059.jpg';
import p67 from '../assets/images/resizedimages/gallery26.jpg';
import p68 from '../assets/images/resizedimages/gallery060.jpg';
import p69 from '../assets/images/resizedimages/gallery054.jpg';
import p70 from '../assets/images/resizedimages/gallery13.jpg';
import p71 from '../assets/images/resizedimages/gallery24.jpg';
import p72 from '../assets/images/resizedimages/gallery057.jpg';
import p73 from '../assets/images/resizedimages/gallery058.jpg';
import p74 from '../assets/images/resizedimages/gallery139.jpg';



function Servicedishes(props) {
    const servicedishes = [
        {id: 43, title: 'Service dishes', price: 25.00, imgSrc: p44, selected: false},
        {id: 44, title: 'Service dishes', price: 25.00, imgSrc: p45, selected: false},
        {id: 45, title: 'Service dishes', price: 25.00, imgSrc: p46, selected: false},
        {id: 46, title: 'Service dishes', price: 40.00, imgSrc: p47, selected: false},
        {id: 47, title: 'Service dishes', price: 20.00, imgSrc: p48, selected: false},
        {id: 48, title: 'Service dishes', price: 10.00, imgSrc: p49, selected: false},
        {id: 49, title: 'Service dishes', price: 45.00, imgSrc: p50, selected: false},
        {id: 50, title: 'Service dishes', price: 30.00, imgSrc: p51, selected: false},
        {id: 51, title: 'Service dishes', price: 35.00, imgSrc: p52, selected: false},
        {id: 52, title: 'Service dishes', price: 45.00, imgSrc: p53, selected: false},
        {id: 53, title: 'Service dishes', price: 50.00, imgSrc: p54, selected: false},
        {id: 54, title: 'Service dishes', price: 75.00, imgSrc: p55, selected: false},
        {id: 55, title: 'Service dishes', price: 15.00, imgSrc: p56, selected: false}, 
        {id: 56, title: 'Service dishes', price: 35.00, imgSrc: p57, selected: false}, 
        {id: 57, title: 'Service dishes', price: 15.00, imgSrc: p58, selected: false},
        {id: 58, title: 'Service dishes', price: 20.00, imgSrc: p59, selected: false}, 
        {id: 59, title: 'Service dishes', price: 50.00, imgSrc: p60, selected: false}, 
        {id: 60, title: 'Service dishes', price: 45.00, imgSrc: p61, selected: false},
        {id: 61, title: 'Service dishes', price: 30.00, imgSrc: p62, selected: false},
        {id: 62, title: 'Service dishes', price: 35.00, imgSrc: p63, selected: false},
        {id: 63, title: 'Service dishes', price: 20.00, imgSrc: p64, selected: false},
        {id: 64, title: 'Service dishes', price: 20.00, imgSrc: p65, selected: false},
        {id: 65, title: 'Service dishes', price: 15.00, imgSrc: p66, selected: false},
        {id: 130, title: 'Service dishes', price: 15.00, imgSrc: p67, selected: false},
        {id: 66, title: 'Service dishes', price: 15.00, imgSrc: p68, selected: false},
        {id: 67, title: 'Service dishes', rice: 25.00, imgSrc: p69, selected: false},
        {id: 68, title: 'Service dishes', price: 10.00, imgSrc: p70, selected: false},
        {id: 69, title: 'Service dishes', price: 15.00, imgSrc: p71, selected: false},
        {id: 70, title: 'Service dishes', price: 35.00, imgSrc: p72, selected: false},
        {id: 71, title: 'Service dishes', price: 40.00, imgSrc: p73, selected: false},
        {id: 72, title: 'Service dishes', price: 40.00, imgSrc: p74, selected: false} 
    ];
    
       

    return (
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center">
        <h3>SERVICE DISHES</h3>
 
                {servicedishes.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts'/>
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
          
    );

    }

export default Servicedishes;