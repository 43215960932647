import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


import first from '../assets/images/whitebackground/gallery03_clipped_rev_1 (1).png';
import second from '../assets/images/otherphotos/finalpics/homepics/gallery29.jpg';
import third from '../assets/images/otherphotos/finalpics/homepics/gallery25.jpg';
import forth from '../assets/images/otherphotos/finalpics/homepics/gallery28.jpg';

import fivth from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery13_clipped_rev_1.png';
import sixth from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery013_clipped_rev_1.png';
import seventh from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery06_clipped_rev_1 (1).png';




function AboutPage(props) {
    return (
        <div className="about-page">
            <Hero subTitle={props.subTitle}/>
            <Content>

            <Container>
                <Row className="row-about d-flex wrap text-about p-1">
                    <Col>
                        <p>Vintage Hub TLV is a second-hand vintage store located in the heart of Tel Aviv-Jaffa, Florentin area that’s considered to be the funkiest place in whole Israel. Surrounded by tons of coffee-shops, bars, pets’ stores, tiny galleries, tattoo places, etc.</p>
                        <p>The store collects and sells the rarest items you can ever have - jewelry, clothing, kitchen and dining staff, furniture, art, books, various collectible items, and others.</p>
                        <p>We accept business and cultural partnership and are willing to distinguish ourselves not just like a regular second-hand  store but a place promoting and passing value to the local art and cultural market.</p>
                    </Col>
                    <Col>           
                        <img src={first} className='w-100' style={{maxWidth: "800px"}} />
                        <p className="textprice">gypseous pumpkin<br></br> 30.00 NIS</p>
                    </Col>
                </Row>
            </Container>
            </Content>
            <Container fluid>
            <Row className="d-flex wrap justify-content-center pt-5 mt-5">
                <Col>
                    <img src={seventh} className="w-100 aboutImage" style={{'width':'80px'}}/>
                    <p className="textprice">indian eldorado plate<br></br> 80.00 NIS</p>
                </Col>
                <Col>
                    <img src={sixth} className="w-100 aboutImage" style={{'width':'80px'}} />
                    <p className="textprice">ceramic penguin<br></br> 20.00 NIS</p>
                </Col>
                <Col >
                    <img src={fivth} className="w-100 aboutImage" style={{'width':'80px'}} />
                    <p className="textprice">glass vintage ball<br></br> 100.00 NIS</p>
                </Col>
            </Row>
            </Container>
            
            <Container fluid>
            <Row className="d-flex row justify-content-center pt-5 mt-5">
                <Col className="p-0">
                    <img src={second} className="w-100 aboutImagefirst" style={{'width':'100px'}} />
                </Col>
                <Col className="p-0">
                    <img src={forth} className="w-100 aboutImagefirst" style={{'width':'100px'}} />
                </Col>
                <Col className="p-0">
                    <img src={third} className="w-100 aboutImagefirst" style={{'width':'100px'}} />
                </Col>
            </Row>
            </Container>


            
            

               
        </div>
    );
}

export default AboutPage; 

