import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// books images
import p121 from '../assets/images/resizedimages/gallery133.jpg';



function Books(props) {
    const books = [
        {id: 118, title: 'Books',price: 75.00, imgSrc: p121, selected: false}
    ];
    
       

    return (
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>BOOKS</h3>

                {books.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts' />
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
      
    );

    }

export default Books;