import React from 'react';
import { useSpring, animated } from 'react-spring';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Homedecor from '../components/Homedecor';
import Servicedishes from '../components/Servicedishes';
import Art from '../components/Art';
import Books from '../components/Books';
import Electronics from '../components/Electronics';
import Toys from '../components/Toys';
import Clothing from '../components/Clothing';
import Jewerly from '../components/Jewerly';
import Card from '../components/Card';
import CategoryProducts from './CategoryProducts';


function Cardinfo(props) {

    let history = useHistory();
    const redirect = () => {
        history.push('/view')
    }

    const style = useSpring({ opacity: 1, from: {opacity: 0}});
    
        return (
            <div>
            <animated.div className="g-card-info" style={style} >
     
                  
                  {(function() {
          let catproduct = props.title;
          switch (catproduct) {
            case 'Art':
              return <Art/>;
            case 'Toys':
              return <Toys />;
            case 'Books':
              return <Books />;
              case 'Electronics':
              return <Electronics />;
              case 'Jewerly':
              return <Jewerly />;
              case 'Homedecor':
              return <Homedecor />;
              case 'Service dishes':
              return <Servicedishes />;
              case 'Clothing and accessorize':
              return <Clothing />;
            default:
              return null;
          }
        })()} 

                        





                  </animated.div>



     
                
     </div>   
);





      }
    

export default Cardinfo;
