import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ProductPage from './pages/ProductPage';
import GalleryPage from './pages/GalleryPage';
import ServicePage from './pages/ServicePage';
import logo from './assets/images/VH2.jpg';





class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'VINTAGE HUB TLV',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'About', path: '/about' },
        { title: 'Contact', path: '/contact' },
        { title: 'Products', path: '/products' },
        { title: 'Gallery', path: '/gallery' },
        { title: 'Services', path: '/services' }
      ],
      home: {
        title: 'VINTAGE HUB TLV',
        subTitle: 'Second-hand store',
        text: 'Checkout our products below'
      }, 
      about: {
        subTitle: 'ABOUT US',
      },
      contact: {
        subTitle: 'CONTACT US',
      },
      product: {
        subTitle: 'OUR PRODUCTS',
        text: "Products' categories"
      },
      gallery: {
        subTitle: 'OUR GALLERY',
        text: "VINTAGE HUB TLV ART COLLECTION "
      },
      service: {
        subTitle: 'OUR SERVICES'
      }
    }
  }
  render() {
    return (
      <Router>
      <Container className="p-0" fluid={true}>
          <Navbar variant="light" className="" expand="lg">
            <Navbar.Brand 
                href="/" className="brand font-weight-bolder" style={{color: '#FF3EA5FF'}}>
                <img 
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block logo align-top"
                />
                  VINTAGE HUB TLV
            </Navbar.Brand>
            <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
            <Navbar.Collapse id="navbar-toggle">
                <Nav className="ml-auto">

                  <Link style={{color: 'black'}} className="nav-link m-2" to="/">home</Link>
                  <Link style={{color: 'black'}} className="nav-link m-2" to="/about">about</Link>
                  <Link style={{color: 'black'}} className="nav-link m-2" to="/contact">contact</Link>
                  <Link style={{color: 'black'}} className="nav-link m-2" to="/products">products</Link>
                  <Link style={{color: 'black'}} className="nav-link m-2" to="/services">services</Link>
                  <Link style={{color: 'black'}} className="nav-link m-2" to="/gallery">gallery</Link>

                </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Route path="/" exact render={() => <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} text={this.state.home.text} />} />
          <Route path="/about" render={() => <AboutPage title={this.state.about.title} subTitle={this.state.about.subTitle} text={this.state.about.text} />} />
          <Route path="/contact" render={() => <ContactPage title={this.state.contact.title} subTitle={this.state.contact.subTitle} text={this.state.contact.text} />} />
          <Route path="/products" render={() => <ProductPage title={this.state.product.title} subTitle={this.state.product.subTitle} text={this.state.product.text} />} />
          <Route path="/gallery" render={() => <GalleryPage title={this.state.gallery.title} subTitle={this.state.gallery.subTitle} text={this.state.gallery.text} />} />
          <Route path="/services" render={() => <ServicePage title={this.state.service.title} subTitle={this.state.service.subTitle} text={this.state.service.text} />} />

          <Footer />
      </Container>
      </Router>
      );
  }
}

export default App;
