import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
//  homedecor images
import p1 from '../assets/images/resizedimages/gallery01.jpg';
import p2 from '../assets/images/resizedimages/gallery107.jpg';
import p3 from '../assets/images/resizedimages/gallery17.jpg';
import p4 from '../assets/images/resizedimages/gallery09.jpg';
import p5 from '../assets/images/resizedimages/gallery050.jpg';
import p6 from '../assets/images/resizedimages/gallery04.jpg';
import p7 from '../assets/images/resizedimages/gallery174.jpg';
import p8 from '../assets/images/resizedimages/gallery176.jpg';
import p9 from '../assets/images/resizedimages/gallery173.jpg';
import p10 from '../assets/images/resizedimages/gallery06.jpg';
import p11 from '../assets/images/resizedimages/gallery011.jpg';
import p12 from '../assets/images/resizedimages/gallery20.jpg';
import p13 from '../assets/images/resizedimages/gallery45.jpg';
import p14 from '../assets/images/resizedimages/gallery171.jpg';
import p15 from '../assets/images/resizedimages/gallery047.jpg';
import p16 from '../assets/images/resizedimages/gallery08.jpg';
import p17 from '../assets/images/resizedimages/gallery004.jpg';
import p18 from '../assets/images/resizedimages/gallery084.jpg';
import p19 from '../assets/images/resizedimages/gallery46.jpg';
import p20 from '../assets/images/resizedimages/gallery35.jpg';
import p21 from '../assets/images/resizedimages/gallery16.jpg';
import p22 from '../assets/images/resizedimages/gallery084.jpg';
import p23 from '../assets/images/resizedimages/gallery11.jpg';
import p24 from '../assets/images/resizedimages/gallery31.jpg';
import p25 from '../assets/images/resizedimages/gallery34.jpg';
import p26 from '../assets/images/resizedimages/gallery07.jpg';
import p27 from '../assets/images/resizedimages/gallery046.jpg';
import p28 from '../assets/images/resizedimages/gallery001.jpg';
import p29 from '../assets/images/resizedimages/gallery049.jpg';
import p30 from '../assets/images/resizedimages/gallery28.jpg';
import p31 from '../assets/images/resizedimages/gallery22.jpg';
import p32 from '../assets/images/resizedimages/gallery003.jpg';
import p33 from '../assets/images/resizedimages/gallery045.jpg';
import p34 from '../assets/images/resizedimages/gallery087.jpg';
import p35 from '../assets/images/resizedimages/gallery016.jpg';
import p36 from '../assets/images/resizedimages/gallery32.jpg';
import p37 from '../assets/images/resizedimages/gallery101.jpg';
import p38 from '../assets/images/resizedimages/gallery088.jpg';
import p39 from '../assets/images/resizedimages/gallery21.jpg';
import p40 from '../assets/images/resizedimages/gallery111.jpg';
import p41 from '../assets/images/resizedimages/gallery090.jpg';
import p42 from '../assets/images/resizedimages/gallery015.jpg';
import p43 from '../assets/images/resizedimages/gallery02.jpg';



function Homedecor(props) {
        const homedecor = [
                {id: 0, title: 'Homedecor', price: 120.00, imgSrc: p1, selected: false},
                {id: 1, title: 'Homedecor', price: 40.00, imgSrc: p2, selected: false},
                {id: 2, title: 'Homedecor', price: 60.00, imgSrc: p3, selected: false},
                {id: 3, title: 'Homedecor', price: 25.00, imgSrc: p4, selected: false},
                {id: 4, title: 'Homedecor', price: 70.00, imgSrc: p5, selected: false},
                {id: 5, title: 'Homedecor', price: 30.00, imgSrc: p6, selected: false},
                {id: 6, title: 'Homedecor', price: 45.00, imgSrc: p7, selected: false},
                {id: 7, title: 'Homedecor', price: 100.00, imgSrc: p8, selected: false},
                {id: 8, title: 'Homedecor', price: 80.00, imgSrc: p9, selected: false},
                {id: 9, title: 'Homedecor', price: 80.00, imgSrc: p10, selected: false},
                {id: 10, title: 'Homedecor', price: 50.00, imgSrc: p11, selected: false},
                {id: 11, title: 'Homedecor',price: 30.00, imgSrc: p12, selected: false},
                {id: 12, title: 'Homedecor', price: 90.00, imgSrc: p13, selected: false},
                {id: 13, title: 'Homedecor', price: 35.00, imgSrc: p14, selected: false},
                {id: 14, title: 'Homedecor', price: 45.00, imgSrc: p15, selected: false},
                {id: 15, title: 'Homedecor', price: 150.00, imgSrc: p16, selected: false},
                {id: 16, title: 'Homedecor', price: 120.00, imgSrc: p17, selected: false},
                {id: 17, title: 'Homedecor', price: 80.00, imgSrc: p18, selected: false},
                {id: 18, title: 'Homedecor', price: 40.00, imgSrc: p19, selected: false},
                {id: 19, title: 'Homedecor', price: 90.00, imgSrc: p20, selected: false},
                {id: 20, title: 'Homedecor', price: 35.00, imgSrc: p21, selected: false}, 
                {id: 21, title: 'Homedecor', price: 50.00, imgSrc: p22, selected: false},
                {id: 22, title: 'Homedecor', price: 80.00, imgSrc: p23, selected: false},
                {id: 23, title: 'Homedecor', price: 50.00, imgSrc: p24, selected: false}, 
                {id: 24, title: 'Homedecor', price: 75.00, imgSrc: p25, selected: false},
                {id: 25, title: 'Homedecor', price: 80.00, imgSrc: p26, selected: false},
                {id: 26, title: 'Homedecor', price: 15.00, imgSrc: p27, selected: false},
                {id: 27, title: 'Homedecor', price: 150.00, imgSrc: p28, selected: false},
                {id: 28, title: 'Homedecor', price: 40.00, imgSrc: p29, selected: false},
                {id: 29, title: 'Homedecor', price: 80.00, imgSrc: p30, selected: false},
                {id: 30, title: 'Homedecor', price: 35.00, imgSrc: p31, selected: false}, 
                {id: 31, title: 'Homedecor', price: 30.00, imgSrc: p32, selected: false}, 
                {id: 32, title: 'Homedecor', price: 65.00, imgSrc: p33, selected: false},
                {id: 33, title: 'Homedecor', price: 60.00, imgSrc: p34, selected: false},
                {id: 34, title: 'Homedecor', price: 45.00, imgSrc: p35, selected: false},
                {id: 35, title: 'Homedecor', price: 70.00, imgSrc: p36, selected: false},
                {id: 36, title: 'Homedecor', price: 25.00, imgSrc: p37, selected: false},
                {id: 37, title: 'Homedecor', price: 40.00, imgSrc: p38, selected: false},
                {id: 38, title: 'Homedecor', price: 45.00, imgSrc: p39, selected: false}, 
                {id: 39, title: 'Homedecor', price: 80.00, imgSrc: p40, selected: false},
                {id: 40, title: 'Homedecor', price: 60.00, imgSrc: p41, selected: false},
                {id: 41, title: 'Homedecor', price: 20.00, imgSrc: p42, selected: false}, 
                {id: 42, title: 'Homedecor', price: 35.00, imgSrc: p43, selected: false}
        ];
       

    return (

        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>HOMEDECOR</h3>

                {homedecor.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts'/>
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
    )

    }

export default Homedecor;