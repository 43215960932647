import React from 'react';

import Hero from '../components/Hero';
import Content from '../components/Content';
import GalleryArt from '../components/GalleryArt';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import first from '../assets/images/otherphotos/finalpics/contactpics/gallery01.jpg';
import second from '../assets/images/otherphotos/gallery08.jpg';

function GalleryPage(props) {

    return (
        <div className="gallery-page">
            <Hero subTitle={props.subTitle}
            text={props.text} />
            <Content className="gallery-category">
           
           <GalleryArt />
            </Content>



            <Container fluid >
            <Row className="d-flex row justify-content-center mt-5 pt-5">
                <Col className="p-0">
                    <img src={first} className="w-100 thumbnail galleryImage" style={{'width': "600px"}} />
                </Col>
                <Col className="p-0">
                    <img src={second} className="w-100 thumbnail galleryImage shadowImage" style={{'width': "600px" }}/>
                </Col>
            </Row>
            </Container>
        </div>
    );
}

export default GalleryPage; 

