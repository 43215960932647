import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Hero(props) {
    return (
        <Jumbotron className="jumbo jumbotron-fluid bg-transparent p-1">
            <Container fluid={true}>
                <Row className="d-flex justify-content-center pt-5">
                    <Col md={8} sm={12} classsName="justify-content-center">

                        { props.title && <h4 className="jumbo mainjumbotext display-2 font-weight-bolder">{props.title}</h4> }
                        { props.subTitle && <h2 className="jumbo display-4 font-weight-light">{props.subTitle}</h2> }
                        { props.text && <h2 className="jumbo lead font-weight-light">{props.text}</h2> }
                    </Col>
                </Row>
            </Container> 

          
        </Jumbotron>
        );
}

export default Hero; 