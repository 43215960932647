import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'




import first from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery1_clipped_rev_2.png';
import second from '../assets/images/whitebackground/gallery004_clipped_rev_1.png';
import third from '../assets/images/whitebackground/gallery09_clipped_rev_1.png';
import forth from '../assets/images/whitebackground/gallery10_clipped_rev_2.png';




function RealC() {
        const [index, setIndex] = useState(0);
      
        const handleSelect = (selectedIndex, e) => {
          setIndex(selectedIndex);
        };
      
        return (
          <Container className="d-flex justify-content-center carouselwindow">
          
          <Carousel className="" activeIndex={index} onSelect={handleSelect}>

            <Carousel.Item className="carousel-img">
              <img
                className="d-block"
                src={first}
                alt="First slide"
              />
              <Carousel.Caption className="caption pt-0">
                <h3>Ceramic Vase</h3>
                <p>150.00 NIS</p>
                <p>Homedesign</p>
              </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item className="carousel-img">
              <img
                className="d-block"
                src={second}
                alt="Second slide"
              />
              <Carousel.Caption className="caption pt-0">
                <h3>Crystal Teapot</h3>
                <p>120.00 NIS</p>
                <p>Homedesign</p>
              </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item className="carousel-img">
              <img
                className="d-block"
                src={third}
                alt="Third slide"
                style={{'paddingBottom': '200px'}}
              />
              <Carousel.Caption  className="caption pt-0 mt-0">
                <h3>Little Angel</h3>
                <p>50.00 NIS</p>
                <p>Homedesign</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className="carousel-img">
              <img
                className="d-block"
                src={forth}
                alt="Forth slide"
              />
              <Carousel.Caption  className="caption pt-0">
                <h3>Glass Vase</h3>
                <p>80.00 NIS</p>
                <p>Homedesign</p>
              </Carousel.Caption>
            </Carousel.Item>


          </Carousel>
          <br/>
        
          </Container>
        );
      }
    


export default RealC; 