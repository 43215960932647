import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


import first from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery20_clipped_rev_1.png';
import third from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery43_clipped_rev_1.png';
import second from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery049_clipped_rev_1.png';


class ContactPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: false,
            emailSent: null
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name; 

        this.setState({
            [name]: value
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true        
        });

        axios.post('http://localhost:3030/api/email', this.state)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        disabled: false,
                        emailSent: true
                    });
                } else {
                    this.setState({
                        disabled: false,
                        emailSent: false
                });
            }     
            })
            .catch(res => {
                this.setState({
                    disabled: false,
                    emailSent: false
                });
            })
 }

    render() {
        return (
            <div className="contact-page">
                <Hero subTitle={this.props.subTitle}/>

                <Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label htmlFor="full-name">Full Name</Form.Label>
                            <Form.Control id="full-name" name="name" type="text" value={this.state.name} onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control id="email" name="email" type="text" value={this.state.email} onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="message">Message</Form.Label>
                            <Form.Control id="message" name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange} />
                        </Form.Group>

                        <Button style={{backgroundColor: '#FF3EA5FF', color: '#fff', border: '#FF3EA5FF'}} className="d-inline-block buttoncontact"  type="submit" disabled={this.state.disabled}>
                            Send
                        </Button>

                        {/* {this.state.emailSent === true && <p className="d-inline success-msg">Email Sent</p>}
                        {this.state.emailSent === false && <p className="d-inline err-msg">Email Not Sent</p>} */}

                    </Form>
            </Content>

            <Container fluid className="pt-5">
            <Row className="d-flex row justify-content-center pt-5">
                <Col style={{'backgroundColor' : '#FE0000FF'}}>
                    <img src={first}  className="w-100 thumbnail" style={{'width':'200px', 'height': 'auto'}} />
                    <p className="textprice">baroque sugarvase<br></br> 50.00 NIS</p>
                </Col>
                <Col style={{'backgroundColor' : '#95DBE5FF'}}>
                    <img src={third} className="w-100 thumbnail" style={{'width':'200px', 'height': 'auto'}}/>
                    <p className="textprice pt-5">german coat<br></br> 200.00 NIS</p>
                </Col>
                <Col style={{'backgroundColor' : '#E0F7FA'}} >
                    <img src={second} className="w-100 thumbnail" style={{'width':'200px', 'height': 'auto'}} />
                    <p className="textprice">ceramic bear<br></br> 40.00 NIS</p>
                </Col>
            </Row>
            </Container>
            </div>
        );
    }
}


export default ContactPage; 