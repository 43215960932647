import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// clothing and accessorize images
import p122 from '../assets/images/resizedimages/gallery078.jpg';
import p123 from '../assets/images/resizedimages/gallery068.jpg';
import p124 from '../assets/images/resizedimages/gallery075.jpg';
import p125 from '../assets/images/resizedimages/gallery074.jpg';
import p126 from '../assets/images/resizedimages/gallery077.jpg';
import p127 from '../assets/images/resizedimages/gallery085.jpg';
import p128 from '../assets/images/resizedimages/gallery071.jpg';
import p129 from '../assets/images/resizedimages/gallery51.jpg';
import p130 from '../assets/images/resizedimages/gallery05.jpg';
import p131 from '../assets/images/resizedimages/gallery14.jpg';

function Clothing(props) {
    const clothing = [
        // clothing and accessorizes
        {id: 119, title: 'Clothing and accessorize', price: 40.00, imgSrc: p122, selected: false},
        {id: 120, title: 'Clothing and accessorize', price: 30.00, imgSrc: p123, selected: false},
        {id: 121, title: 'Clothing and accessorize', price: 35.00, imgSrc: p124, selected: false},
        {id: 122, title: 'Clothing and accessorize', price: 20.00, imgSrc: p125, selected: false},
        {id: 123, title: 'Clothing and accessorize', price: 40.00, imgSrc: p126, selected: false},
        {id: 124, title: 'Clothing and accessorize', price: 200.00, imgSrc: p127, selected: false},
        {id: 125, title: 'Clothing and accessorize', price: 40.00, imgSrc: p128, selected: false},
        {id: 126, title: 'Clothing and accessorize', price: 25.00, imgSrc: p129, selected: false},
        {id: 127, title: 'Clothing and accessorize', price: 350.00, imgSrc: p130, selected: false},
        {id: 128, title: 'Clothing and accessorize', price: 200.00, imgSrc: p131, selected: false}
    ];
    
       

    return (
      
        <Container className="productsByCat" fluid>
        <Row className="d-flex justify-content-center"> 
        <h3>CLOTHING AND ACCESSORIZES</h3>

                {clothing.map(el => 
                        <div className="p-2 m-2">
                        <img src={el.imgSrc} alt={el.imgSrc} className='imagesOfProducts'/>
                        <p>{el.price} {'NIS'}</p>
                        </div>)}
        </Row>
        </Container>
           
    );

    }

export default Clothing;