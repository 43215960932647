import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


import first from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery078_clipped_rev_2.png';
import second from '../assets/images/whitebackground/gallery046_clipped_rev_1.png';
import third from '../assets/images/whitebackground/gallery12_clipped_rev_1.png';

import forth from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery03_clipped_rev_1 (1).png';
import fivth from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery41_clipped_rev_1.png';
import sixth from '../assets/images/otherphotos/newwhitebackgroundphotos/gallery22_clipped_rev_1.png';



function ServicePage(props) {
    return (
        <div className="service-page">
            <Hero subTitle={props.subTitle} />
            <Content>
            <Row className="row-service d-flex wrap text-service">
                <Col>
                <h4>Repair and renovation</h4>
                <p>Repair and renovation. "CrazyHands" brand that's considered to be the part of "Vintage Hub TLV" focuses on bringing qualitative and modern repair of your houses.</p>
                <img src={forth} className="w-100 pt-5 mt-5 thumbnail" style={{'width':'300px'}} />
                <p className="textprice">venetian plate<br></br> 60.00 NIS</p>
                </Col>
                
                <Col>
                <h4>Interior design</h4>
                <p>Interior design. Vintage Hub TLV specializes in home design, color solution, etc. The customer has an opportunity to receive discount of 15% on all our products, while using this service. </p>
                <img src={fivth} className="w-100 pt-5 mt-5 thumbnail" style={{'width':'300px'}} />
                <p className="textprice pt-5 mt-5">sheepskin coat<br></br> 300.00 NIS</p>
                </Col>
                
                <Col>
                <h4>Repair</h4>
                <p>Repair and mending of any items like painting frames, watches, restoration of vintage and non-vintage items.</p>
                <img src={sixth} className="w-100 pt-5 mt-5 thumbnail" style={{'width':'300px'}} />
                <p className="textprice">brittish fellow candlestick<br></br> 35.00 NIS</p>
                </Col>
            </Row>
            </Content>

            <Container fluid className="pt-5 mt-5">
            <Row className="d-flex row justify-content-center pt-5">
                <Col style={{'backgroundColor' : '#57A773'}}>
                    <img src={second} className="w-100 thumbnail" style={{'width':'300px'}} />
                    <p className="textprice">wooden glass-holder<br></br> 15.00 NIS</p>
                </Col>
                <Col style={{'backgroundColor' : '#fb743e'}}>
                    <img src={first} className="w-100 thumbnail" style={{'width':'300px'}} />
                    <p className="textprice">pink tie with royal pattern<br></br> 40.00 NIS</p>
                </Col>
                <Col style={{'backgroundColor' : '#2BAE66FF'}}>
                    <img src={third} className="w-100 thumbnail" style={{'width':'300px'}} />
                    <p className="textprice">golden pumpkin<br></br> 30.00 NIS</p>

                </Col>
            </Row>
            </Container>
            
        </div>
    );
}

export default ServicePage; 