import React from 'react';
import CardPieceInfo from './CardPieceInfo';

function CardPiece(props) {

    return (
        <div className="d-inline-block g-card" onClick={(e) => props.click(props.piece)}>
        <div>{props.piece.price}</div>
            <img className="g-card-image" src={props.piece.imgSrc} alt={props.piece.imgSrc} />
            {props.piece.selected && <CardPieceInfo title={props.piece.price} link={props.piece.link} />}
           
        </div>
        
    );
}

export default CardPiece;